/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */


// react
import { useMaterialUIController, setDarkMode } from "/context";

// next components
import dynamic from "next/dynamic";
import Image from 'next/image';
import Link from "next/link";

// Images
import logoCT from "/assets/img/logo.png";

// config
import config from "/config";

// MUI
const Container = dynamic(() => import("@mui/material/Container"), { loading: () => null });
const Grid = dynamic(() => import("@mui/material/Grid"), { loading: () => null });
const MuiLink = dynamic(() => import('@mui/material/Link'), { loading: () => null });
const Switch = dynamic(() => import('@mui/material/Switch'), { loading: () => null });

// PRO
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });

// dynamic icons
const Facebook = dynamic(() => import('@mui/icons-material/Facebook'), { loading: () => null });
const Twitter = dynamic(() => import('@mui/icons-material/Twitter'), { loading: () => null });
const YouTube = dynamic(() => import('@mui/icons-material/YouTube'), { loading: () => null });
const Instagram = dynamic(() => import('@mui/icons-material/Instagram'), { loading: () => null });

// dynamic pro
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });

const menus = [
  {
    name: "company",
    items: [
      { name: "about us", route: "/about-us" },
      { name: "reviews", route: "/reviews" },
      { name: "privacy", route: "/privacy" },
      { name: "our work", route: "/our-work" },
    ],
  },
  {
    name: "Outdoor Living",
    items: [
      { name: "Pools", route: "/our-work/feature/pools" },
      { name: "Patios", route: "/our-work/feature/patios" },
      { name: "Outdoor Kitchens", route: "/our-work/feature/outdoor-kitchens" },
      { name: "Fireplaces", route: "/our-work/feature/fireplaces" },
      { name: "Fire Pits", route: "/our-work/feature/firepits" },
      { name: "Ponds and Waterfalls", route: "/our-work/feature/ponds-and-waterfalls" },
    ],
  },
  {
    name: "Curb Appeal",
    items: [
      { name: "Driveways", route: "/our-work/feature/driveways" },
      { name: "Entrways", route: "/our-work/feature/entryways" },
      { name: "Walkways", route: "/our-work/feature/walkways" },

    ],
  },
  {
    name: "Everywhere",
    items: [
      { name: "Retaining Walls", route: "/our-work/feature/retaining-walls" },
      { name: "Plantings", route: "/our-work/feature/plantings" },
      { name: "Landscape Lighting", route: "/our-work/feature/lighting" },
    ],
  },
];

function DefaultFooter() {
  const [controller, dispatch] = useMaterialUIController();

  return (
    <MDBox component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MDBox>
              <Link href="/">
                <Image
                  src={logoCT}
                  alt="Autumn Leaf"
                  sx={{ maxWidth: "2rem" }}
                  width={40}
                  height={40}
                  mb={2} />
              </Link>
              <MDTypography variant="h6" component="h2">Autumn Leaf</MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" mt={3}>
              <MuiLink
                href={config.FbURL}
                target="_blank"
                rel="noreferrer"
                mr={2.5}
                sx={{ opacity: 0.8 }}
                aria-label="Autumn Leaf's Facebook Profile"
              >
                <Facebook color={controller.darkMode ? "white" : "dark"} />
              </MuiLink>
              <MuiLink
                href={config.YoutubeURL}
                target="_blank"
                rel="noreferrer"
                mr={2.5}
                sx={{ opacity: 0.8 }}
                aria-label="Autumn Leaf's YouTube Profile"
              >
                <YouTube color={controller.darkMode ? "white" : "dark"} />
              </MuiLink>
              <MuiLink
                href={config.TwitterURL}
                target="_blank"
                rel="noreferrer"
                mr={2.5}
                sx={{ opacity: 0.8 }}
                aria-label="Autumn Leaf's Twitter Profile"

              >
                <Twitter color={controller.darkMode ? "white" : "dark"} />
              </MuiLink>
              <MuiLink
                href={config.InstagramURL}
                target="_blank"
                rel="noreferrer"
                mr={2.5}
                sx={{ opacity: 0.8 }}
                aria-label="Autumn Leaf's Instagram Profile"
              >
                <Instagram color={controller.darkMode ? "white" : "dark"} />
              </MuiLink>
            </MDBox>
          </Grid>
          {menus.map(({ name: title, items }, menuIndex) => (
            <Grid key={`menu-${title}-${menuIndex}`} item xs={6} md={2} sx={{ mb: 3 }}>
              <MDTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MDTypography>
              <MDBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }, index) => (
                  <MDBox key={`${name}-${index}`} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MDTypography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        aria-label={name}
                      >
                        {name}
                      </MDTypography>
                    ) : (
                      <Link href={route}>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                          sx={{ cursor: "pointer" }}
                          aria-label={name}
                        >
                          {name}
                        </MDTypography>
                      </Link>
                    )}
                  </MDBox>
                ))}
              </MDBox>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            <MDTypography variant="button" fontWeight="regular">
              All rights reserved. Copyright &copy; {new Date().getFullYear()}{" "}
              <Link href="/">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                >
                  Autumn Leaf Landscape Design
                </MDTypography>
              </Link>.&nbsp;
              <Link href="/privacy">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{ borderBottom: "1px solid #212121" }}
                >
                  Privacy Policy
                </MDTypography>
              </Link>.&nbsp;
              <MDTypography
                component="a"
                href="https://codefox.com"
                target="_blank"
                rel="noreferrer noopener"
                variant="button"
                fontWeight="regular"
                sx={{ borderBottom: "1px solid #212121" }}
              >Web Developer</MDTypography>:{" "}
              CodeFox.
            </MDTypography>{' '}
            <MDTypography variant="button" fontWeight="regular">
              <Switch
                id="light-dark-mode-switch"
                checked={controller.darkMode}
                onChange={() => setDarkMode(dispatch, !controller.darkMode)} />{' '}
              <MDTypography
                component="label"
                variant="button"
                htmlFor="light-dark-mode-switch"
              >Dark mode</MDTypography>
            </MDTypography>
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default DefaultFooter;
